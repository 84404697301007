import React from "react"
import Layout from "../components/layout";
import LayoutSimple from "../components/layout-simple";
import styled from "styled-components";
const FormWrapper = styled('div')`
  max-width: 720px;
  label {
    color: black;
  }
`;

const SuccessPage = () => (
    <LayoutSimple location="success" >
        <main>
          <h1>Message Received - We'll be in touch.</h1>
          <h3>Thank you for sending us your message.</h3>
          <p>Having a dog join your family is an important decision. That's why we're always here to answer any questions you have about owning a Doodles and we typically respond within 24 hours.
          </p>

        </main>
    </LayoutSimple>
)
export default SuccessPage